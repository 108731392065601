<template>
  <component :is="headerData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="headerData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching header data
      </h4>
      <div class="alert-body">
        No header found with this header id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-headers-list'}"
        >
          Header List
        </b-link>
        for other headers.
      </div>
    </b-alert>

    <b-tabs
      v-if="headerData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Header</span>
        </template>
        <header-edit-tab-account
          :header-data="headerData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import headerStoreModule from '../headerStoreModule'
import HeaderEditTabAccount from './HeaderEditTabAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    HeaderEditTabAccount,    
  },
  setup() {
    const headerData = ref(null)

    const HEADER_APP_STORE_MODULE_NAME = 'app-header'
    if (!store.hasModule(HEADER_APP_STORE_MODULE_NAME)) store.registerModule(HEADER_APP_STORE_MODULE_NAME, headerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(HEADER_APP_STORE_MODULE_NAME)) store.unregisterModule(HEADER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-header/fetchHeader', { id: router.currentRoute.params.id })
      .then(response => { headerData.value = response.data.data })
      .catch(error => {
        console.error(error)
        if (error.response.status === 404) {
          headerData.value = undefined
        }
      })

    return {
      headerData,
    }
  },
}
</script>

<style>

</style>
