<template>
  <div>

    <!-- Header Info: Input Fields -->
    <b-form>
      <b-row>
          
        <!-- Field: Header seeker -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Seeker"
            label-for="seeker"
          >
            <b-form-checkbox
              switch
              id="seeker"
              v-model="headerData.seeker"
            />
          </b-form-group>
        </b-col>

        <!-- Field: logo hide-->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Logo hide"
            label-for="logohide"
          >
            <b-form-checkbox
              switch
              id="logohide"
              v-model="headerData.logo.hide"
            />
          </b-form-group>
        </b-col>

        <!-- Field: logo -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Logo"
            label-for="logo"
          >
            <b-form-file
              id="logo"
              v-model="headerData.logo.background_image"
            />
          </b-form-group>
        </b-col>

         <!-- Field: background color -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Background color"
            label-for="backgroundcolor"
          >
            <b-form-input
              type="color"
              id="backgroundcolor"
              v-model="headerData.background_color"
            />
          </b-form-group>
        </b-col>

          <!-- Field: background image -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Background image"
            label-for="backgroundimage"
          >
            <b-form-file
              id="backgroundimage"
              v-model="headerData.background_image"
            />
          </b-form-group>
        </b-col>

         <!-- Field: auth color -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Auth color"
            label-for="authcolor"
          >
            <b-form-input
              type="color"
              id="authcolor"
              v-model="headerData.auth.color"
            />
          </b-form-group>
        </b-col>

         <!-- Field: auth backgroud_color -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Auth background color"
            label-for="authbgcolor"
          >
            <b-form-input
            type="color"
              id="authbgcolor"
              v-model="headerData.auth.background_color"
            />
          </b-form-group>
        </b-col>

         <!-- Field: auth backgroud_image -->
        <b-col
          
          cols="12"
          md="4"
        >
          <b-form-group
            label="Auth background image"
            label-for="authbgimage"
          >
            <b-form-file v-model="headerData.auth.background_image"></b-form-file>
          </b-form-group>
        </b-col>
       
      </b-row>

    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateHeader(headerData._id)"
    >
      Save Changes
    </b-button>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BFormFile ,BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BFormFile,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    vSelect,
  },
  props: {
    headerData: {
      type: Object,
      required: true,
    },
  },
  mounted(){
    console.log(this.headerData)
  },
  methods: {
   
    async updateHeader(Id) {
      try{
        const form = new FormData()
        const data = this.headerData
        console.log(data)
        form.append('seeker', data.seeker)
        form.append('background_color', data.background_color)
        form.append('background_image', data.background_image)
        form.append('logo_hide', data.logo.hide)
        form.append('logo_background_image', data.logo.background_image)
        form.append('auth_color', data.auth.color)
        form.append('auth_background_color', data.auth.background_color)
        form.append('auth_background_image', data.auth.background_image)
        await store.dispatch('app-header/updateHeader', { id: Id, headerData: form})
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Header updated',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch(error){
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating header',
            icon: 'EditIcon',
            variant: 'error',
          },
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
